import { AppConfig } from 'app-config';

export const appConfig: AppConfig = {
  app: {
    name: 'recrewt-web',
    title: 'RECREWT - Webmatching',
    version: '2.0.0',
    locale: 'by',
  }, //done
  theme: {
    isDark: false,
    primary: '#ff8f00',
    accent: '#e53a7d',
    warn: '#f44336',
    radar: null,
    useCompanyTheme: true,
  }, //done
  modules: {
    story: {
      disabled: false,
      mode: 0,
      session: null,
      location: null,
      radius: null,
      askLocation: true,
      askStartYear: true,
      altCompletedMessage: null,
      askNogos: true,
      placeholderQuestion: null,
    },
    browser: {
      disabled: true,
    },
    matching: {
      showApply: true,
      disabled: false,
      allowCompanyMatches: true,
      trackStats: false,
      useRoadmap: false,
      showRoadmapDownload: false,
      showAppDownload: true,
      allowRegenerate: true,
      altDownloadButtonText: null,
      hideRestart: true,
      tutorial: null,
      links: [],
      ratedTutorial: null,
      roadmapOnly: false,
      externalJobActions: [],
    },
    home: {
      disabled: false,
      imageSrc: null,
      auth: true,
      passwords: ['RECREWT2023'],
      titleSuffix: null,
      welcomeMessageLines: [],
      footerImageSrc: null,
      fundedByImageSrc: null,
      logoSrc: null,
    },
  },
};
